import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import MainScreenSecond from '../components/UI/MainScreenSecond/MainScreenSecond';
import ContactsPage from '../components/Pages/ContactsPage/ContactsPage';
import Instagram from '../components/Layout/Instagram/Instagram';

const Contacts = ({data}) => {
  const { t } = useTranslation();

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Контакти',
      title_ru: 'Контакты',
      title_en: 'Contacts',
      to: '/contacts/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <MainScreenSecond
        mainData={data.strapiContacts}
        crumbsNav={crumbsNav}
      />
      <ContactsPage contactsData={data.strapiContacts}/>
      <Instagram />
    </Layout>
  );
};

export default Contacts;

export const query = graphql`
query Contacts($language: String!) {
  strapiContacts {
      title_ua
      title_ru
      title_en
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      address_ua
      address_ru
      address_en
      google_maps_link
      instagram
      facebook
      youtube
      tel {
        telephone
      }
      email {
        email
      }
    }
  locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`