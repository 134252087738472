import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import Map from '../../../../svg/map.svg';
import Marker from '../../../../svg/location.svg';

const ContactsMap = ({contactsData}) => {
  const langToggle = useLanguage;
  return (
    <div className="contacts-left">
      <a
        href={contactsData.google_maps_link}
        target="blank"
        className="map-link"
      >
        <Map className="map" />
        <span className="marker-wrapper">
          <Marker className="marker-icon" />
          <span className="marker-title">
            {langToggle(
              'Відкрити на Гугл Карті',
              'Открыть на Гугл Карте',
              'Open on Google Map'
            )}
          </span>
        </span>
      </a>
    </div>
  );
};

export default ContactsMap;
