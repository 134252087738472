import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import LogoIcon from '../../../svg/icon-logo.svg';

const Instagram = () => {

  return (
    <section className="instagram" data-scroll-section>
      <div className="container-fluid">
        <div className="instagram-content">
          <div className="instagram-content__bg">
            <StaticImage 
              src="../../../images/instagram.jpeg" 
              className="instagram-image" 
              alt="instagram" 
              placeholder="blurred"
            />
          </div>
          <a
            href="https://www.instagram.com/o.x.x.clinic/"
            className="instagran-link"
            target="blank"
          >
            <span className="inst-center">
              <span className="inst-icon">
                <span className="inst-rings">
                  <span className="inst-ring__ringlet"></span>
                  <span className="inst-ring__ringlet"></span>
                  <span className="inst-ring__ringlet"></span>
                </span>
                <LogoIcon />
              </span>
              <span className="inst-title">Instagram</span>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Instagram;
