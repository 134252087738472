import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import ContactsSocials from './ContactsSocials/ContactsSocials';

const ContactsInfo = ({contactsData}) => {
  const langToggle = useLanguage;
  return (
    <div className="contacts-right">
      <div className="contacts-info">
        <div className="contacts-info__item">
          <span className="contacts-info__title">
            {langToggle('Адреса:', 'Адрес:', 'Address:')}
          </span>
          <p className="contacts-info__adress">
            {langToggle(
              contactsData.address_ua,
              contactsData.address_ru,
              contactsData.address_en,
            )}
          </p>
        </div>
        <div className="contacts-info__item">
          <span className="contacts-info__title">
            {langToggle('Телефон:', 'Телефон:', 'Phone:')}
          </span>
          {contactsData.tel.map((item, i) => {
            return(
              <a
                key={i}
                href={'tel:' + item.telephone}
                className="contacts-info__link contacts-info__phone"
              >
              {item.telephone}
            </a>
            )
          })}
        </div>
        <div className="contacts-info__item">
          <span className="contacts-info__title">E-mail:</span>
          {contactsData.email.map((mail, i) => {
            return(
          <a
            key={i}
            href={'mailto:' + mail.email}
            className="contacts-info__link"
          >
            {mail.email}
          </a>
            )
          })}
        </div>
        <ContactsSocials contactsData={contactsData} />
      </div>
    </div>
  );
};

export default ContactsInfo;
