import React from 'react';
import ContactsInfo from './ContactsInfo/ContactsInfo';
import ContactsMap from './ContactsMap/ContactsMap';

const ContactsPage = ({contactsData}) => {
  return (
    <section className="contacts">
      <ContactsMap  contactsData={contactsData}/>
      <ContactsInfo contactsData={contactsData}/>
    </section>
  );
};

export default ContactsPage;
