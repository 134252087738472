import React from 'react';
import Facebook from '../../../../../svg/facebook.svg';
import Instagram from '../../../../../svg/instagram.svg';
import Youtube from '../../../../../svg/youtube.svg';

const ContactsSocials = ({contactsData}) => {
  return (
    <ul className="contacts-socials">
      <li className="contacts-socials__item">
        <a
          href={contactsData.instagram}
          target="blank"
          className="contacts-socials__link"
        >
          <Instagram />
        </a>
      </li>
      <li className="contacts-socials__item">
        <a
          href={contactsData.facebook}
          target="blank"
          className="contacts-socials__link"
        >
          <Facebook />
        </a>
      </li>
      <li className="contacts-socials__item">
        <a
          href={contactsData.youtube}
          target="blank"
          className="contacts-socials__link"
        >
          <Youtube />
        </a>
      </li>
    </ul>
  );
};

export default ContactsSocials;
