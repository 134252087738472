import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../Hooks/useLanguage';
import {fromCategorySlugToUrl} from '../../../utils/utils'


const MainScreenSecond = ({ crumbsNav, mainData }) => {
  const langToggle = useLanguage;
  const image = getImage(mainData?.image?.localFile);
 
  return (
   
    <section id="scroller-top" className="mainScreen-second scroller-top">
      <div className="mainScreen-second__bg">
        <GatsbyImage 
          image={image} 
          className="mainScreen-second__image" 
          alt={langToggle(
            mainData.title_ua,
            mainData.title_ru,
            mainData.title_en
          )}
        />
      </div>
      <div className="mainScreen-second__center">
        <h1 className="h1">
          {langToggle(
            mainData.title_ua,
            mainData.title_ru,
            mainData.title_en
          )}
        </h1>
      </div>
      <div className="mainScreen-second__bottom">
        <ul className="crumbsNav">
          {crumbsNav.map((item, index) => {
            return (
              <li className="crumbsNav-item" key={index}>
                <Link className="crumbsNav-item__link" to={item.to}>
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </Link>
              </li>
            );
          })}
            <li className={mainData ? "crumbsNav-item" : null}>
                <Link className="crumbsNav-item__link" to={mainData ? fromCategorySlugToUrl(mainData.slug) : null}>
                  {mainData ? langToggle(mainData.title_ua, mainData.title_ru, mainData.title_en) : null}
                </Link>
              </li>
        </ul>
      </div>
    </section>
  );
};

export default MainScreenSecond;
